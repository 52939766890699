<template functional>
    <footer class="flex flex-wrap justify-center text-sm text-gray-500 pt-6">
        <a href="tel:+41628233330" class="hover:text-gray-300 focus:text-gray-300 focus:outline-none mx-4 mb-2">062 823 33 30</a>
        <a href="mailto:info@h2g.ch" class="hover:text-gray-300 focus:text-gray-300 focus:outline-none mx-4 mb-2">info@h2g.ch</a>
        <a href="https://www.h2g.ch/" class="hover:text-gray-300 focus:text-gray-300 focus:outline-none mx-4 mb-2">www.h2g.ch</a>
    </footer>
</template>

<script>
export default {
};
</script>
