<template>
    <form method="post" action="https://webmail.your-server.de/login.php" class="w-full" @submit="onSubmit">
        <input type="hidden" name="app" id="app" value="" />
        <input type="hidden" name="login_post" id="login_post" value="1" />
        <input type="hidden" name="url" value="" />
        <input type="hidden" name="anchor_string" id="anchor_string" value="" />

        <UsernameField ref="username" label="E-Mail" placeholder="Zum Beispiel info@ihr-domainname.ch" name="horde_user"/>
        <PasswordField ref="password" label="Passwort" name="horde_pass"/>
        <SubmitButton/>
    </form>
</template>

<script>
import UsernameField from "@/components/UsernameField";
import PasswordField from "@/components/PasswordField";
import SubmitButton from "@/components/SubmitButton";

const FIELDS = ['username', 'password'];

export default {
    components: { SubmitButton, PasswordField, UsernameField },

    methods: {
        onSubmit(event) {
            if (this.hasErrors()) {
                event.preventDefault();
            }
        },

        hasErrors() {
            return FIELDS.reduce((carry, field) => {
                return this.$refs[field].hasErrors() || carry;
            }, false);
        },
    },
};
</script>
