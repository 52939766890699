<template functional>
    <button
        class="border border-white rounded-xl hover:bg-white hover:text-gray-800 focus:bg-white focus:text-gray-800 transition-colors uppercase text-center font-bold tracking-wide w-full focus:outline-none p-2"
        type="submit"
    >
        Anmelden
    </button>
</template>

<script>
export default {
};
</script>
