<template>
    <div class="flex flex-col w-full mb-12">
        <label for="input-password" class="font-serif text-3xl mb-1">Passwort</label>

        <div class="relative">
            <input
                class="bg-transparent border-b-3 text-sm placeholder-gray-500 w-full focus:outline-none py-2"
                :class="{'border-red': showErrors}"
                v-model="value"
                :type="type"
                id="input-password"
                :name="name"
                placeholder="Passwort"
                @blur="onBlur"
            >

            <button type="button"
                    class="text-gray-500 hover:text-white focus:text-white absolute right-0 h-full focus:outline-none"
                    @click="onShowPassword"
                    :title="title">
                <svg
                    id="visibility-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    class="fill-current"
                    xml:space="preserve">
                    <path v-if="! showPassword" d="M12,4.5C7,4.5,2.7,7.6,1,12c1.7,4.4,6,7.5,11,7.5s9.3-3.1,11-7.5C21.3,7.6,17,4.5,12,4.5z M12,17c-2.8,0-5-2.2-5-5
                        s2.2-5,5-5s5,2.2,5,5S14.8,17,12,17z M12,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z"/>
                    <path v-else d="M12,7c2.8,0,5,2.2,5,5c0,0.6-0.1,1.3-0.4,1.8l2.9,2.9c1.5-1.3,2.7-2.9,3.4-4.7c-1.7-4.4-6-7.5-11-7.5c-1.4,0-2.7,0.2-4,0.7
                        l2.2,2.2C10.7,7.1,11.4,7,12,7z M2,4.3l2.3,2.3L4.7,7c-1.7,1.3-3,3-3.7,5c1.7,4.4,6,7.5,11,7.5c1.6,0,3-0.3,4.4-0.8l0.4,0.4l2.9,2.9
                        l1.3-1.3L3.3,3L2,4.3z M7.5,9.8l1.5,1.6C9,11.6,9,11.8,9,12c0,1.7,1.3,3,3,3c0.2,0,0.4,0,0.6-0.1l1.6,1.5C13.5,16.8,12.8,17,12,17
                        c-2.8,0-5-2.2-5-5C7,11.2,7.2,10.5,7.5,9.8z M11.8,9l3.1,3.1l0-0.2c0-1.7-1.3-3-3-3L11.8,9z"/>
                </svg>
            </button>
        </div>

        <ErrorMessage v-show="showErrors" message="Dieses Feld muss ausgefüllt sein."/>
    </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage";

export default {
    components: { ErrorMessage },
    props: {
        name: String,
    },

    data() {
        return {
            showPassword: false,
            value: '',
            showErrors: false,
        };
    },

    computed: {
        type() {
            return this.showPassword ? 'text' : 'password';
        },

        title() {
            return this.showPassword ? 'Passwort verstecken' : 'Passwort anzeigen';
        },
    },

    methods: {
        onShowPassword() {
            this.showPassword = ! this.showPassword;
        },

        hasErrors() {
            this.showErrors = this.value === '';

            return this.showErrors;
        },

        onBlur() {
            if (this.showErrors) {
                this.hasErrors();
            }
        },
    },
};
</script>
