<template>
    <div class="flex flex-wrap w-full text-center mb-16">
        <h2 class="sr-only">Wähle die gewünschte Funktion aus</h2>
        <button
            class="w-1/2 border border-white font-bold tracking-wide rounded-l-xl focus:outline-none py-2"
            :class="{'bg-white text-gray-800': value === 'hosting'}"
            @click="onSelect('hosting')"
        >
            Hosting
        </button>
        <button
            class="w-1/2 border border-white font-bold tracking-wide rounded-r-xl focus:outline-none py-2"
            :class="{'bg-white text-gray-800': value === 'webmail'}"
            @click="onSelect('webmail')"
        >
            Webmail
        </button>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
    },

    methods: {
        onSelect(button) {
            this.$emit('input', button);
        },
    },
};
</script>
