<template>
    <form
        method="post"
        action="https://konsoleh.your-server.de/login.php"
        enctype="application/x-www-form-urlencoded"
        class="w-full"
        @submit="onSubmit"
    >
        <UsernameField ref="username" label="Login" placeholder="Zum Beispiel ihr-domainname.ch" name="login_user_inputbox"/>
        <PasswordField ref="password" label="Passwort" name="login_pass_inputbox"/>
        <SubmitButton/>
    </form>
</template>

<script>
import UsernameField from "@/components/UsernameField";
import PasswordField from "@/components/PasswordField";
import SubmitButton from "@/components/SubmitButton";

const FIELDS = ['username', 'password'];

export default {
    components: { SubmitButton, PasswordField, UsernameField },

    methods: {
        onSubmit(event) {
            if (this.hasErrors()) {
                event.preventDefault();
            }
        },

        hasErrors() {
            return FIELDS.reduce((carry, field) => {
                return this.$refs[field].hasErrors() || carry;
            }, false);
        },
    },
};
</script>
