<template>
    <div class="flex flex-col w-full mb-12">
        <label for="input-username" class="font-serif text-3xl mb-1" v-text="label"/>
        <input
            class="bg-transparent border-b-3 text-sm placeholder-gray-500 focus:outline-none py-2"
            :class="{'border-red': showErrors}"
            v-model="value"
            type="text"
            id="input-username"
            :name="name"
            :placeholder="placeholder"
            @blur="onBlur"
        >

        <ErrorMessage v-show="showErrors" message="Dieses Feld muss ausgefüllt sein."/>
    </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage";

export default {
    components: { ErrorMessage },
    props: {
        name: String,
        label: String,
        placeholder: String,
    },

    data() {
        return {
            value: '',
            showErrors: false,
        };
    },

    methods: {
        hasErrors() {
            this.showErrors = this.value === '';

            return this.showErrors;
        },

        onBlur() {
            if (this.showErrors) {
                this.hasErrors();
            }
        },
    },
};
</script>
