<template>
    <div class="flex items-center py-2">
        <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 20 20"
            height="20"
            width="20"
            xml:space="preserve"
        >
            <circle id="Oval" fill="#ff0000" fill-rule="evenodd" clip-rule="evenodd" cx="10" cy="10" r="10"/>
            <path id="_x30_1" fill="#ffffff" d="M13.4,6.1c-0.2,0.2-0.5,0.5-0.7,0.7c-0.6,0.6-1.2,1.2-1.7,1.7c-0.7,0.7-1.4,1.4-2.1,2.1
                    c-0.6,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9c0,0,0,0,0,0c-0.2,0.2-0.2,0.5,0,0.7c0.2,0.2,0.5,0.2,0.7,0
                    c0.2-0.2,0.5-0.5,0.7-0.7c0.6-0.6,1.2-1.2,1.7-1.7c0.7-0.7,1.4-1.4,2.1-2.1c0.6-0.6,1.2-1.2,1.8-1.8c0.3-0.3,0.6-0.6,0.9-0.9
                    c0,0,0,0,0,0c0.2-0.2,0.2-0.5,0-0.7C13.9,6,13.6,5.9,13.4,6.1L13.4,6.1z"/>
            <path id="_x30_2" fill="#ffffff" d="M6.1,6.8c0.2,0.2,0.5,0.5,0.7,0.7C7.4,8.1,8,8.7,8.6,9.3c0.7,0.7,1.4,1.4,2.1,2.1
                    c0.6,0.6,1.2,1.2,1.8,1.8c0.3,0.3,0.6,0.6,0.9,0.9c0,0,0,0,0,0c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7
                    c-0.2-0.2-0.5-0.5-0.7-0.7c-0.6-0.6-1.2-1.2-1.7-1.7c-0.7-0.7-1.4-1.4-2.1-2.1C8.9,8.2,8.3,7.6,7.7,7C7.4,6.7,7.1,6.4,6.8,6.2
                    c0,0,0,0,0,0C6.6,6,6.3,5.9,6.1,6.1C6,6.3,5.9,6.6,6.1,6.8L6.1,6.8z"/>
        </svg>

        <span class="text-sm" v-text="message"/>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
    },
};
</script>
