<template>
    <div id="app" class="flex flex-col bg-gray-800 text-white min-h-screen p-4">
        <h1 class="sr-only">{{ appName }}</h1>
        <main class="flex flex-grow flex-col justify-center items-center w-full mx-auto mb-8" v-cloak>
            <TargetSwitcher v-model="target"/>

            <HostingLoginForm v-if="target === 'hosting'"/>
            <WebmailLoginForm v-if="target === 'webmail'"/>
        </main>
        <Footer/>
    </div>
</template>

<script>
import TargetSwitcher from './components/TargetSwitcher.vue';
import Footer from "@/components/Footer";
import HostingLoginForm from "@/components/HostingLoginForm";
import WebmailLoginForm from "@/components/WebmailLoginForm";

export default {
    components: {
        WebmailLoginForm,
        HostingLoginForm,
        Footer,
        TargetSwitcher,
    },

    data() {
        return {
            appName: process.env.VUE_APP_NAME,
            target: 'hosting',
        };
    },

    computed: {},
};
</script>

<style>
#app {
    min-height: 100vh;
}

main {
    max-width: 439px;
}
</style>
